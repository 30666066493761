import React, { Component } from "react";

import Banner from "../utils/Banner";
//import bookImage from "../images/flips_book_2017.png";
//import bookImage1 from "../images/flips_geschenk2018.png";

import bookImage2 from "../images/zettel_2025.png";
// import bookImage2 from "../images/flips_2022_p.png";
//import bookImage2 from "../images/flips_2020_p.png";

//let reservationLink =
//  "https://www.graff.de/shop/add/702850FLIPSV2/Vorbestellung%20flips%20Gutscheinbuch%202024/17%2C95/Vorbestellpreis%2017%2C95%20EUR%2C%20später%2018%2C95%20EUR.%20Sobald%20das%20flips%20Gutscheinbuch%20eingetroffen%20ist%2C%20werden%20Sie%20per%20E-Mail%20benachrichtigt.%20Bitte%20holen%20Sie%20Ihr%20flips%20Gutscheinbuch%20zwischen%20dem%201.%20und%20dem%2015.12.2023%20ab..html";

let reservationLink =
  "https://www.graff.de/shop/item/9925432000514/flips-gutscheinbuch-2025-vorbestellpreis-kartoniertes-buch";

export default class Reservation extends Component {
  render() {
    return (
      <div>
        <Banner>
          <h1 className="flips-banner-header">Online reservieren</h1>
        </Banner>

        <div
          className="container content-container"
          style={{ marginTop: 40, marginBottom: 200, fontSize: 16 }}
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-5">
              <a href={reservationLink} target="_blank" id="AO9">
                <img
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    paddingRight: 60,
                    maxHeight: 360,
                  }}
                  src={bookImage2}
                  className="img-responsive hidden-xs"
                  role="presentation"
                />
              </a>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-7"
              style={{ marginTop: 10 }}
            >
              {/* <h1 className="flips-banner-header">Sicher ist sicher!</h1> */}
              <p>
                Die flips Ausgabe 2025 ist ab dem 01.12.2024 zum Preis von
                18,95&nbsp;€ erhältlich.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Sichern Sie sich Ihr flips Gutscheinbuch und reservieren Sie
                jetzt die von Ihnen gewünschte Menge zum Vorzugspreis in Höhe
                von 17,95&nbsp;€ pro Exemplar!
                {/* Bis zum 30.11.2018 sparen
                Sie einen Euro pro Exemplar. */}
              </p>
              <p>
                Sobald flips im Handel verfügbar ist, werden Sie benachrichtigt.
                Ihre Bestellung liegt dann zur Abholung bei der Buchhandlung
                Graff bereit. Die Bezahlung erfolgt bei Abholung.
              </p>

              <p>flips Gutscheinbuch – das lohnt sich immer.</p>

              <p>
                <a
                  className="btn btn-info"
                  style={{ fontSize: 18, marginTop: 10 }}
                  href={reservationLink}
                  target="_blank"
                  id="AO9"
                >
                  flips reservieren (bei Buchhandlung Graff)
                </a>
              </p>

              {/* <p style={{ marginTop: 20 }}>
                Die Vorschau auf die flips Gutscheine 2018 stellen wir Mitte
                November für Sie online.
              </p> */}
              {/* <p>
                Natürlich können sie flips auch nach dem 30.11. online
                bestellen. Die Abwicklung erfolgt auch in diesem Fall über die
                Buchhandlung Graff.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
